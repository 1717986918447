.container {
  padding: 1.5rem;
  display: flex;
}

.logo {
  display: flex;
  align-items: center;
  flex: 1;
  gap: 1rem;
}

.logo > img {
  width: 3.5rem;
  height: 3.5rem;
}

.logo > span {
  font-weight: 600;
}

.right {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.bars{
    display: none;
}

.menu {
  display: flex;
  /* align-items: center; */
  gap: 2rem;
  list-style: none;
  font-weight: 500;
}

.menu > li:hover {
  color: #fe956f;
  cursor: pointer;
}

.search {
  outline: none;
  border: none;
  padding: 0.5rem;
  border-radius: 0.5rem;
  width: 7rem;
  height: 30%;
}

.cart {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}

@media screen and (max-width: 825px) {
  .right {
    position: absolute;
    right: 2rem;
    z-index: 9999;
    background-color: #fe956f;
    color: black;
    border-radius: 5px;
    flex-direction: column;
    padding: 1rem;
  }

  .menu {
    flex-direction: column;
    margin-left: -2rem;
    display: none;
  }

  .cart{
    display: none;
  }

  .menu > li:hover {
    color: #f8e367;
    cursor: pointer;
  }

  .bars{
    display: block;
  }

  .search{
    display: none;
  }
}


