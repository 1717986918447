.container{
    padding: 0 2rem;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
}

.wrapper{
    display: flex;
    align-items: baseline;
    justify-content: center;
    position: relative;
}

.wrapper>img{
    width: 25rem;
    position: absolute;
    bottom: 0;
}
.blueCircle{
    width: 25rem;
    height: 25rem;
    border-radius: 50%;
    z-index: -99;
    position: absolute;
    bottom: 0;
    background: linear-gradient(135.74deg,#ea08d7 , #fe956f , #6dd3fe );
}

.cart2{
    display: flex;
    position: absolute;
    right: -20%;
    bottom: 25%;
    gap: 1rem;
    align-items: center;
}

.cart2>svg{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: white;
    padding: 10px;
    border: 5px solid var(--black);
}

.signup{
    display: flex;
    background: white;
    padding: 10px;
    border-radius: 15px;
    font-size: .8rem;
    gap: 1rem;
    align-items: center;
    box-shadow: var(--shadow1);
}

.signup>:first-child{
    width: 6rem;
    display: block;
}

.signup>:nth-child(2){
    border-radius: 50%;
    border: 1px solid skyblue;
    display: flex;
    width: 20px;
    height: 20px;
    padding: 5px;
    align-items: center;
    justify-content: center;

}



.h_sides{
    display: grid;
    grid-template-rows: 1fr 1fr;
}

.text1{
    text-transform: uppercase;
    font-style: 1.5rem;
    width: min-content;
    display: block;
    font-weight: 600;
}

.text2{
    display: flex;
    flex-direction: column;
    width: min-content;
    color: black;
}

.text2>:first-child{
    font-weight: 800;
    font-size: 2.5rem;
}

.text2>:nth-child(2){
    display: block;
}


.traffic{
    display: flex;
    flex-direction: column;
    text-align: right;
}

.traffic>:first-child{
    font-size: 2.5rem;
    font-weight: 800;
}

.customer{
    display: flex;
    flex-direction: column;
    text-align: right;
}

.customer>:first-child{
    font-size: 2.5rem;
    font-weight: 800;
}












@media screen and (max-width: 856px) {
    .cart2{
        display: flex;
        position: absolute;
        right: -10%;
        bottom: 5%;
        gap: 1rem;
        align-items: center;
    }
    .cart2>svg{
        width: 24px;
        height: 24px;
        border: 3px solid var(--black);
    }
    
    .signup{
        padding: 6px;
        font-size: .7rem;
        gap: .5rem;
    }
}

@media screen and (max-width: 700px) {
    .container{
        padding-bottom: 5rem;
    }
    .blueCircle{
        width: 18rem;
        height: 18rem;
        top: 0;
    }

    .wrapper>img{
        width: 18rem;
        position: absolute;
        top: 0;
    }

    .h_sides{
        display: grid;
        grid-template-rows: .5fr .8fr;
        gap: 1rem;
    }

    .text2>:first-child{
        font-size: 1.5rem;
    }
    
    .text2>:nth-child(2){
        font-size: .8rem;
        text-align: start;
    }

    .traffic{
        padding-right: 0%;
        margin-right: 0%;
    }

    .traffic>:first-child{
        font-size: 1.5rem;
    }

    .traffic>:nth-child(2){
        font-size: .8rem;
    }
    
    .customer>:first-child{
        font-size: 1.5rem;
    }

    .customer>:nth-child(2){
        font-size: .8rem;
    }

    .cart2{
        right: 20%;
        bottom: -25%;
    }
    
}


@media screen and (max-width: 550px){
    .text1, .text2>span:nth-of-type(2){
        font-size: .9rem;
        text-align: start;
    }
    .text2>span:nth-of-type(1),
    .traffic>span:nth-of-type(1),
    .customer>span:nth-of-type(1){
        font-size: 1.5rem;
        text-align: left;
    }

    .cart2{
        right: 20%;
        bottom: 10%;
    }

    .container{
        grid-template-areas: 
        'left center center'
        'right right right';
    }

    .container>:first-child{
        grid-area: left;
        grid-template-rows: none;
        gap: 8rem;
    }

    .container>:nth-child(2){
        grid-area: center;
    }

    .container>:nth-child(3){
        grid-area: right;
        display: flex;
        justify-content: space-around;
        padding-top: 1rem;
    }
}




@media screen and (max-width: 375px){
    .text1, .text2>span:nth-of-type(2){
        font-size: .9rem;
        text-align:start;
    }
    .text2>span:nth-of-type(1),
    .traffic>span:nth-of-type(1),
    .customer>span:nth-of-type(1){
        font-size: 1.5rem;
        text-align: left;
    }

    .cart2{
   transform: scale(.7);
    }

    .container{
        grid-template-areas: 
        'left center center'
        'right right right';
    }

    .container>:first-child{
        grid-area: left;
        grid-template-rows: none;
        gap: 2rem;
    }

    .container>:nth-child(2){
        grid-area: center;
    }

    .container>:nth-child(3){
        grid-area: right;
        display: flex;
        justify-content: space-around;
        padding-top: 1rem;
    }

    .blueCircle{
        width: 10rem;
        height: 10rem;
        top: 0;
    }

    .wrapper>img{
        width: 10rem;
        position: absolute;
        top: 0;
    }
}

