.whole{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4rem 2rem;
}
.testimonial{
    display: grid;
    grid-template-columns: 1.5fr 3fr 1.5fr;
    align-items: center;
    justify-content: center;

}

.testimonial>img{
    width: 20rem;
    /* margin: 0 auto; */
    justify-self: center;
}

.wrapper{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.wrapper>:nth-child(1){
    font-size: 2.5rem;
    font-weight: bold;
    text-align: left;
    text-transform: uppercase;
}


.wrapper>:nth-child(2){
    font-size: 0.8rem;
    text-transform: uppercase;
}

.container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.container>:nth-child(1){
    font-size: 2.5rem;
    font-weight: bold;
    text-align: right;
    text-transform: uppercase;
}


.container>:nth-child(2){
    font-size: 0.8rem;
    text-align: right;
    text-transform: uppercase;
}


/* reviews */

.reviews{
    font-size: 2rem;
    font-weight: bold;
    text-transform: uppercase;
}

.carousal{
    width: 100%;
}

.tCarousel{
    padding: 2rem;
}

.testimonials{
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: white;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    gap: 1rem;
    box-shadow: 0rem 1rem 3rem -50px #7d7d7d ;
}

.testimonials>img{
    width: 3rem;
    justify-self: center;
    position: absolute;
    top: -2rem;
    left: 45%;
}

.comment{
    margin-top: 2rem;
    font-size: 0.8rem;
    text-align: center;
}

.testimonials>hr{
    height: 1px;
    width: 80%;
    background: black;
    border: none;
}

.name{
    font-weight: bold;
}




@media screen and (max-width:640px){
    .testimonial{
        grid-template-columns: 1fr;
        margin-bottom: 4rem;
    }

    .wrapper{
        font-size: 0.8rem;
        text-transform: uppercase;
        font-weight: bold;
        padding: 0 3rem;
    }

    .container{
        padding: 0 3rem;
        font-weight: bold;
    }
}