.cFooterWrapper{
    width: 100%;
    /* margin-top: 5rem; */
    align-items: center;
    margin: 0 auto;
}

.cFooterWrapper>hr{
    width: 100%;
    height: 1px;
    border: none;
    background-color: white;
    margin-top: 1rem;
}

.whole{
    display: flex;
    justify-content: space-evenly;
    width: 90%;
}

.cFooter{
    display: flex;
    width: 100%;
    padding: 1rem;
}

.logo{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: 10%;
}

.logo>span{
    font-weight: 500;
}

.logo>img{
    width: 40px;
}

.block{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 12rem;
}

.detail{
    display: flex;
    flex-direction: column;
    width: inherit;
    font-size: .7rem;
    gap:1rem;
    font-style: italic;
}

.detail:hover{
    cursor: pointer;
}

.detail>:nth-child(1){
    font-size: 1rem;
    font-weight: bold;
}

.pngLine{
    display: flex;
    justify-content: flex-start;
    gap: .5rem;
}

.icon{
    width: 20px;
}

.copyRight{
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    font-size: .6rem;
    padding: 1rem 0;
}

@media screen and (max-width:768px){
.cFooter{
    padding: 2rem;
}
}


@media screen and (max-width:640px){

    .logo{
        width: 100%;
        justify-content: flex-start;
    }

    .cFooter{
        flex-direction: column;
        gap: 2rem;
        padding: 2rem;

    }
    .whole{
        gap: 2rem;
    }
    }

    @media screen and (max-width:640px){
        .cFooter{
            justify-content: center;

        }
        .whole{
            flex-direction: column;
            justify-content: center;
           margin:0 auto;
           width: fit-content;
        } 
    }